import React from "react";
import { Label } from "../../../../../components/formInputs";
import {
    AutoComplete,
    DatePicker,
    NumberTextField,
    PhoneNumberField,
    SelectField,
    SSNNumberField,
    TextField,
} from "../../../../../components/formikFormInputs";
import { validatePhoneNumber } from "../../../../../utils/validation";
import { AllianzLifeCarrierDetail, genderDropDown, otherIndustryId, USA } from "../../../../../constants";
import {
    countryDropDownArr,
    employedTimeDropDown,
    getMonths, getNextYears,
    healthDropdownOtpions,
    stateDropDownArr,
} from "./PersonalDetailUtils";
import HealthTypeToolTip from "./HealthTypeToolTip";
import moment from "moment";
import { checkProofExpiry, noop } from "../../../../../utils/commonUtils";
import GoogleAutoComplete from "../../../../../components/formikFormInputs/GoogleAutoComplete";
import { FormikContextType } from "formik/dist/types";
import { AddressType } from "../../../../../hooks/usePlacesRef";
function PersonalDetailFieldObj(){
}

PersonalDetailFieldObj.prototype.fieldObj = function (conditionalRequired, birthStateValue, setBirthStateValue, setBirthCountryValue, clientSigned, birthCountryValue) {
    return [
        {
            name: "identityId",
            helperFieldText: "Must be a valid drivers license",
            label: <Label label={"Enter drivers license number"} required={conditionalRequired}/>,
            isRequired: conditionalRequired,
            className: "mt35 TextFieldWidth",
            filedType: "string",
            component: TextField,
            variant: "outlined",
        },
        {
            name: "employeeSSN",
            id: "employeeSSN",
            helperFieldText: "What's ITIN.",
            label: <Label label={"Enter ITIN Number"} required={conditionalRequired}/>,
            isRequired: conditionalRequired,
            component: SSNNumberField,
            filedType: "string",
            variant: "outlined",
            className: "TextFieldWidth",
        },
        {
            id: "blotSSN",
            name: "blotSSN",
            component: TextField,
            className: "TextFieldWidth mt15",
            label: "SSN (last 4 digits)",
            disabled: true,
            variant: "outlined",
        },
        {
            id: "birthCountry",
            name: "birthCountry",
            label: <Label label={"Place of Birth"} required={conditionalRequired} />,
            isRequired: conditionalRequired,
            placeholder: "Search Country",
            cb: noop,
            defaultValue: birthCountryValue,
            component: AutoComplete,
            className: "SelectFieldWidth",
            variant: "outlined",
            contentList: countryDropDownArr,
            onSelectChange: (val: string) => {
                // country changes, set contry value
                if (typeof setBirthCountryValue === "function") {
                    setBirthCountryValue(val);
                }
                // country changes, set state value as empty
                if (typeof setBirthStateValue === "function") {
                    setBirthStateValue("");
                }
            },
            disabled: !!clientSigned,
            needUpdate: !!birthStateValue,
            browserSuggestions: false,
        },
    ];
}
PersonalDetailFieldObj.prototype.fieldObj12 = function(conditionalRequired, clientSigned, setBirthStateValue,birthStateValue) {
    return [
        {
            id: "birthState",
            name: "birthState",
            label: <Label label={"Birth State"} required={conditionalRequired} />,
            isRequired: conditionalRequired,
            placeholder: "Search State",
            cb: (value) => {
            },
            component: AutoComplete,
            className: "SelectFieldWidth",
            defaultValue: birthStateValue,
            variant: "outlined",
            contentList: stateDropDownArr,
            onSelectChange: (val: string) => {
                if (typeof setBirthStateValue === "function") {
                    setBirthStateValue(val);
                }
            },
            disabled: !!clientSigned,
            // needUpdate: birthCountryValue === USA ? !!birthStateValue : "",
            needUpdate: !!birthStateValue,
            browserSuggestions: false,
        },
    ];
};
PersonalDetailFieldObj.prototype.fieldObj13 = function(conditionalRequired) {
    return [
        {
            id: "birthState",
            name: "birthState",
            label: <Label label={"Birth State"} required={conditionalRequired} />,
            isRequired: conditionalRequired,
            placeholder: "Search State",
            component: TextField,
            className: "SelectFieldWidth",
            variant: "outlined",
            browserSuggestions: false,
        },
    ];
};
PersonalDetailFieldObj.prototype.fieldObj1 = function (conditionalRequired, getContributionAmount, setProvideCorrectEmailPopUp, isFromClientDetail) {
    let emailObj: any = {
        id: "email",
        name: "email",
        label: <Label label={"Email"} required={conditionalRequired}/>,
        isRequired: conditionalRequired,
        disabled: true,
        component: TextField,
        variant: "outlined",
        className: "TextFieldWidth",
    }
    if (!isFromClientDetail) {
        emailObj = {...emailObj, helperFieldText: <span>if this is not your correct email <a onClick={()=>{
                setProvideCorrectEmailPopUp(true);
            }}><strong className="textRed">click here</strong></a> to update.</span>}
    }
    return  [
        {
            name: "firstName",
            label: <Label label={"First Name"} required={conditionalRequired}/>,
            isRequired: conditionalRequired,
            helperFieldText: "Must match drivers license",
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        },
        {
            name: "middleName",
            label: "Middle Name",
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        },
        {
            name: "lastName",
            label: <Label label={"Last Name"} required={conditionalRequired}/>,
            isRequired: conditionalRequired,
            helperFieldText: "Must match drivers license",
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        },
        {
            id: "cellPhone",
            name: "cellPhone",
            label: <Label label={"Cell Phone"} required={conditionalRequired}/>,
            isRequired: conditionalRequired,
            component: PhoneNumberField,
            validate: validatePhoneNumber,
            variant: "outlined",
            className: "TextFieldWidth",
        },
        {
            ...emailObj
        },
        {
            id: "workPhoneNumber",
            name: "workPhoneNumber",
            label: <Label label={"Work Phone"} required={conditionalRequired}/>,
            helperFieldText: "Enter cell or home number if no work phone.",
            isRequired: conditionalRequired,
            component: PhoneNumberField,
            validate: validatePhoneNumber,
            variant: "outlined",
            className: "TextFieldWidth",
        },
        {
            id: "homePhone",
            name: "homePhone",
            label: <Label label={"Home Phone"} required={conditionalRequired} />,
            helperFieldText: "Enter cell number if no home phone",
            isRequired: conditionalRequired,
            component: PhoneNumberField,
            validate: validatePhoneNumber,
            variant: "outlined",
            className: "TextFieldWidth",
        },
        {
            id: "gender",
            name: "gender",
            label: <Label label={"Gender"} required={conditionalRequired}/>,
            isRequired: conditionalRequired,
            component: SelectField,
            className: "SelectFieldWidth",
            variant: "outlined",
            options: genderDropDown.map((gender:any)=> {
                if(gender.id === 0){
                    return {...gender, id : ""}
                }
                return gender;
            }),
            // onSelectChange: (val: number) => {
            // },
            getValuesOnChange: (val: number, setValue: Function, formValues: any) => {
                getContributionAmount({
                    contributionAmount: 0,
                    age: formValues.age,
                    rating: formValues.healthType,
                    gender: val,
                });
            }
        }
    ];
}

PersonalDetailFieldObj.prototype.fieldObj2 = function(conditionalRequired, setStateValue, communityStateVal, clientSigned) {
    return [
        {
            name: "street",
            label: <Label label={"Street"} required={conditionalRequired}/>,
            isRequired: conditionalRequired,
            filedType: "string",
            component: GoogleAutoComplete,
            variant: "outlined",
            onSelectAddress: (address: AddressType,  {setFieldValueC}) =>{
                     console.log(address,"address ==== ")
                     address.city && setFieldValueC("city", address.city)
                     address.state &&  setFieldValueC("state", address.state)
                     address.street && setFieldValueC("street", address.street)
                     address.zipCode && setFieldValueC("zipCode", address.zipCode)
                     address.state && setStateValue(address.state)
            },
            className: "TextFieldWidth",
        }, {
            name: "city",
            label: <Label label={"City"} required={conditionalRequired}/>,
            isRequired: conditionalRequired,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        },
        {
            id: "state",
            name: "state",
            label: <Label label={"State"} required={conditionalRequired}/>,
            isRequired: conditionalRequired,
            placeholder: "Search State",
            cb: (value)=> {
            },
            component: AutoComplete,
            className: "SelectFieldWidth",
            variant: "outlined",
            contentList: stateDropDownArr,
            onSelectChange: (val: string) => {
                if(typeof setStateValue === 'function') {
                    setStateValue(val);
                }
            },
            disabled: !!clientSigned,
            needUpdate: !!communityStateVal,
            browserSuggestions: false
        },
        {
            name: "zipCode",
            label: <Label label={"Zip Code"} required={conditionalRequired}/>,
            isRequired: conditionalRequired,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        }];
}

PersonalDetailFieldObj.prototype.fieldObj3 = function(isGiCorpIncomeCond, conditionalRequired, currentYear, offerType
, strategySlug, nationalLifeId, carrierId, fullTimeSelected, industryDropDown, setIsIndustryOther, setIndustryId, occupationDropDownArr
) {
    return [
        {
            name: "employer",
            label: <Label label={"Company Name"} required={!!(isGiCorpIncomeCond && conditionalRequired)}/>,
            isRequired: !!(isGiCorpIncomeCond && conditionalRequired),
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        },
        {
            name: "employedTime",
            label: <Label label={"Time Employed"} required={isGiCorpIncomeCond && conditionalRequired}/>,
            isRequired: !!(isGiCorpIncomeCond && conditionalRequired),
            helperFieldText: "With your current employer.",
            component: SelectField,
            className: "SelectFieldWidth",
            variant: "outlined",
            options: employedTimeDropDown,
            // onSelectChange: (val: number) => {
            //
            // },
            getValuesOnChange: (val: number, setValue: Function, formValues: any) => {
                setValue("employedTime", val);
            },
        },
        {
            name: "industryId",
            label: <Label label={"Industry"} required={conditionalRequired}/>,
            isRequired: !!conditionalRequired,
            component: SelectField,
            className: "SelectFieldWidth",
            variant: "outlined",
            options: industryDropDown,
            getValuesOnChange: (val: number, setValue: Function, formValues: any) => {
                setIsIndustryOther(val === otherIndustryId);
                setValue("occupationId", null);
                setIndustryId(val);
                setValue("industryId", val);
            },
        },
        {
            name: "otherIndustry",
            label: <Label label={"Other Industry"} required={conditionalRequired}/>,
            isRequired: !!conditionalRequired,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        },
        {
            name: "occupationId",
            label: <Label label={"Occupation"} required={conditionalRequired}/>,
            isRequired: !!conditionalRequired,
            component: SelectField,
            className: "SelectFieldWidth",
            variant: "outlined",
            options: occupationDropDownArr,
            // onSelectChange: (val: number) => {
            //
            // },
            getValuesOnChange: (val: number, setValue: Function, formValues: any) => {
            },
        },
        {
            name: "occupation",
            label: <Label label={nationalLifeId && carrierId && nationalLifeId === carrierId ? "Industry and Occupation" : "Occupation and Specific Work Duties"} required={isGiCorpIncomeCond && conditionalRequired}/>,
            isRequired: !!(isGiCorpIncomeCond && conditionalRequired),
            helperFieldText: "Your position and a short description of what you do.",
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            md: "8",
        },
        {
            name: "otherOccupation",
            label: <Label label={"Other Occupation"} required={!!conditionalRequired}/>,
            isRequired: !!conditionalRequired,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        },
        {
            name: "occupationDetail",
            label: <Label label={"Occupation Detail"} required={!!conditionalRequired}/>,
            isRequired: !!conditionalRequired,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        },

        /*{
            id: "annualIncome",
            name: "annualIncome",
            label: <Label label={`${currentYear - 1}(year) Annual Income`} required={isGiCorpIncomeCond && conditionalRequired}/>,
            isRequired: ( offerType !== "gi" || ((offerType === "gi" || offerType === "consent") && strategySlug === "cashstream") ) && isGiCorpIncomeCond && conditionalRequired ? true : false,
            helperFieldText: "Your personal income.",
            component: NumberTextField,
            variant: "outlined",
            className: "TextFieldWidth dollerIcon",
            dollarSign: true,
        },
        {
            id: "annualIncome1",
            name: "annualIncome1",
            label: <Label label={`${currentYear - 2}(year) Annual Income`} required={isGiCorpIncomeCond && conditionalRequired}/>,
            isRequired: (offerType !== "gi" && isGiCorpIncomeCond && conditionalRequired && !fullTimeSelected) ? true : false,
            //filedType: "number",
            helperFieldText: "Your personal income.",
            component: NumberTextField,
            variant: "outlined",
            className: "TextFieldWidth dollerIcon",
            dollarSign: true,
        },
        {
            id: "annualIncome2",
            name: "annualIncome2",
            label: <Label label={`${currentYear - 3}(year) Annual Income`} required={isGiCorpIncomeCond && conditionalRequired}/>,
            isRequired: (offerType !== "gi" && isGiCorpIncomeCond && conditionalRequired && !fullTimeSelected) ? true : false,
            helperFieldText: "Your personal income.",
            component: NumberTextField,
            variant: "outlined",
            className: "TextFieldWidth dollerIcon",
            dollarSign: true,
        },
        {
            id: "netWorth",
            name: "netWorth",
            label: <Label label={"Net Worth"} required={isGiCorpIncomeCond && conditionalRequired}/>,
            isRequired: isGiCorpIncomeCond && conditionalRequired,
            helperFieldText: "Can be combined with a spouse.",
            component: NumberTextField,
            variant: "outlined",
            className: "TextFieldWidth dollerIcon",
            dollarSign: true,
        },
        {
            id: "householdAnnualIncome",
            name: "householdAnnualIncome",
            label: <Label label={"Spouse Annual Income"} required={false}/>,
            isRequired: false,
            component: NumberTextField,
            variant: "outlined",
            className: "TextFieldWidth dollerIcon",
            dollarSign: true,
        },
        {
            id: "householdNetWorth",
            name: "householdNetWorth",
            label: <Label label={"Household Net Worth"} required={conditionalRequired}/>,
            isRequired: !((offerType === "gi" || offerType === "consent") && strategySlug === "cashstream") && conditionalRequired,
            helperFieldText: "This factors in spouses income if applicable.",
            component: NumberTextField,
            variant: "outlined",
            className: "TextFieldWidth dollerIcon",
            dollarSign: true,
        }*/
    ];
}
PersonalDetailFieldObj.prototype.fieldObj4 = function (conditionalRequired, setAnticipatedAge, getDays, days, generateSaveRequest) {

    return [
        {
            id: "dob",
            name: "dob",
            label: "label",
            isRequired: conditionalRequired,
            placeholder: "Issue Date",
            component: DatePicker,
            disableFuture: true,
            className: "fullWidthcustomFields"
        }

    ];
}

PersonalDetailFieldObj.prototype.fieldObj5 = function(isGiCorpIncomeCond, conditionalRequired,isGWTGen2=false) {
console.log(isGWTGen2,";;;;;")
    return [
        {
            name: "employerAddress",
            label: <Label label={"Employer Address"} required={!!(isGiCorpIncomeCond && conditionalRequired)}/>,
            isRequired: !!(isGiCorpIncomeCond && conditionalRequired),
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        },
        {
            name: "countryOfOrigin",
            label: <Label label={"Country of origin of funds for premium payment"} required={!!(isGiCorpIncomeCond && conditionalRequired)}/>,
            isRequired: !!(isGiCorpIncomeCond && conditionalRequired),
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        },
        {
            name: "sourceOfFunds",
            label: <Label label={"Source of funds used in contract"} required={!!(isGiCorpIncomeCond && conditionalRequired)}/>,
            isRequired: !!(isGiCorpIncomeCond && conditionalRequired),
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        },
        {
            name: "countrySourceOfWealth",
            label: <Label label={"Country of source of wealth"} required={!!(isGiCorpIncomeCond && conditionalRequired)}/>,
            isRequired: !!(isGiCorpIncomeCond && conditionalRequired),
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        },
        {
            name: "whereTheyProceed",
            label: <Label label={"Please explain how the funds were originated and/or accumulated, not just the location where they proceed from"} required={!!(isGiCorpIncomeCond && conditionalRequired)}/>,
            isRequired: !!(isGiCorpIncomeCond && conditionalRequired),
            component: TextField,
            disabled: isGWTGen2,
            variant: "outlined",
            className: "TextAreaFieldWidth",
            multiline: true,
            rows: 3,
            size: {
                md: 12,
                lg: 12,
            },
            InputLabelProps: {
                shrink: true,
            },
        },
    ];
}

PersonalDetailFieldObj.prototype.fieldObj6 = function (conditionalRequired, client, ageSelected, getContributionAmount) {
    return [
        {
            name: "employerContribution",
            id: "employerContribution",
            label: <Label label={"Yearly Employer Contribution"} required={false}/>,
            isRequired: false,
            helperFieldText: "",
            component: NumberTextField,
            variant: "outlined",
            className: "TextFieldWidth dollerIcon",
            // onSelectChange: (val: number) => {
            // },
            disabled: true,
            dollarSign: true,
        },
        {
            name: "contributionAmount",
            id: "contributionAmount",
            label: <Label label={"Client Annual Contribution Amount"} required={false}/>,
            isRequired: false,
            helperFieldText: !conditionalRequired && !client.dob && !ageSelected ?
                    <span className="colorRed">Please enter a birth date</span> : "",
            component: NumberTextField,
            variant: "outlined",
            className: "TextFieldWidth dollerIcon",
            // onSelectChange: (val: number) => {
            // },
            disabled: !conditionalRequired && !client.dob && !ageSelected,
            dollarSign: true,
        }, {
            name: "healthType",
            label: <Label label={"Health"} required={false}/>,
            isRequired: false,
            filedType: "selectbox",
            helperFieldText: <>Learn about health options <HealthTypeToolTip /></>,
            component: SelectField,
            className: "SelectFieldWidth",
            variant: "outlined",
            options: healthDropdownOtpions,
            // onSelectChange: (val: number) => {
            // },
            getValuesOnChange: (val: number, setValue: Function, formValues: any) => {
                getContributionAmount({
                    contributionAmount: 0,
                    age: formValues.age,
                    rating: val,
                    gender: formValues.gender,
                });
            },
        }];
}

PersonalDetailFieldObj.prototype.fieldObj7 = function (conditionalRequired, setProofExpired) {
    return  [
        {
            name: "proofExpiryMonth",
            component: SelectField,
            className: "SelectFieldWidth",
            variant: "outlined",
            isRequired: conditionalRequired,
            filedType: "selectbox",
            label: <Label label={"Month"} required={conditionalRequired}/>,
            options: getMonths(),
            // onSelectChange: (val: number) => {
            // },
            getValuesOnChange: (val: number, setValue: Function, formValues: any) => {
                if (val && formValues["proofExpiryYear"]) {
                    const endOfMonth = moment(formValues["proofExpiryYear"] + "-" + val).endOf("month").format("D");
                    const date = new Date(formValues["proofExpiryYear"] + "-" + val + "-" + endOfMonth);
                    const result = checkProofExpiry(date);
                    setProofExpired(result);
                }
            },
        }, {
            name: "proofExpiryYear",
            component: SelectField,
            className: "SelectFieldWidth",
            variant: "outlined",
            isRequired: conditionalRequired,
            label: <Label label={"Year"} required={conditionalRequired}/>,
            options: getNextYears(),
            // onSelectChange: (val: number) => {
            // },
            getValuesOnChange: (val: number, setValue: Function, formValues: any) => {
                if (val && formValues["proofExpiryMonth"]) {
                    const endOfMonth = moment(val + "-" + formValues["proofExpiryMonth"]).endOf("month").format("D");
                    const date = new Date(val + "-" + formValues["proofExpiryMonth"] + "-" + endOfMonth);
                    const result = checkProofExpiry(date);
                    setProofExpired(result);
                }
            },
        }, {
            name: "employeeIdState",
            label: <Label label={"State"} required={conditionalRequired}/>,
            isRequired: conditionalRequired,
            component: TextField,
            className: "TextFieldWidth",
            variant: "outlined",
            options: stateDropDownArr,
            // onSelectChange: (val: number) => {
            // },
        },
    ];
}

PersonalDetailFieldObj.prototype.fieldObj8 = function (conditionalRequired, carrierName) {
    const countryCitizenshipLabel:string = AllianzLifeCarrierDetail.name === carrierName ? "Current citizenship/nationality" : "What Country is Your Current Citizenship?";
    return [
        {
            name: "howLongInUS",
            label: <Label label={"How long have you been in the United States?"} required={!!conditionalRequired}/>,
            isRequired: !!conditionalRequired,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        },
        {
            name: "countryCitizenship",
            label: <Label label={countryCitizenshipLabel} required={!!conditionalRequired}/>,
            isRequired: !!conditionalRequired,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        }
    ];
}
PersonalDetailFieldObj.prototype.fieldObj9 = function(conditionalRequired) {
    return [
        {
            name: "immigrationStatus",
            label: <Label label={"Which of the following best describes your immigration status?"} required={conditionalRequired}/>,
            isRequired: !!conditionalRequired,
            options : [
                {
                    label :'Hold a greencard or permanent residency card.',
                    value : "PR",
                    textField:{
                        id: "greenCardNo",
                        name: "greenCardNo",
                        label : "Card Number",
                        component: TextField,
                        isRequired: !!conditionalRequired,
                        variant: "outlined",
                        className: "TextFieldWidth",
                    }
                },
                {
                    label :'Applying for a greencard or permanent residency card.',
                    value : "APPLY_PR"
                },
                {
                    label :'Have a VISA, plan to permanently reside in the U.S., just need to start the application process.',
                    value : "VISA_PLAN",
                    textField:{
                        id: "typeOfVisaPlanHeld",
                        name: "typeOfVisaPlanHeld",
                        label : "Type of VISA held",
                        component: TextField,
                        isRequired: !!conditionalRequired,
                        variant: "outlined",
                        className: "TextFieldWidth",
                    }
                },
                {
                    label :'Visiting the U.S. with a VISA.',
                    value : "VISA",
                    textField:{
                        id: "typeOfVisaHeld",
                        name: "typeOfVisaHeld",
                        label : "Type of VISA held",
                        component: TextField,
                        isRequired: !!conditionalRequired,
                        variant: "outlined",
                        className: "TextFieldWidth",
                    }
                },
                {
                    label :'Other',
                    value : "OTHER",
                    textField:{
                        id: "immigrationStatusOther",
                        name: "immigrationStatusOther",
                        label : "Please Explain",
                        component: TextField,
                        isRequired: !!conditionalRequired,
                        variant: "outlined",
                        className: "TextFieldWidth",
                    }
                }
            ]
        },
        {
            name: "residenceInHomeCountry",
            label: <Label label={"Do you maintain a residence or business in your home country?"} required={!!conditionalRequired}/>,
            isRequired: !!conditionalRequired,
            options : [
                {
                    label : 'No',
                    value: "0"
                },
                {
                    label : 'Yes',
                    value: "1",
                    textField:{
                        id : "residenceInHomeCountryExplain",
                        name : "residenceInHomeCountryExplain",
                        label : <Label label={"Please Explain"} required={!!conditionalRequired}/>,
                        component: TextField,
                        isRequired: !!conditionalRequired,
                        variant: "outlined",
                        className: "TextFieldWidth",
                    }
                }
            ]
        }
    ];
}
PersonalDetailFieldObj.prototype.fieldObj10 = function(conditionalRequired) {
    return [
        {
            name: "currentResidenceCountry",
            label: <Label label={"Country of current residence"} required={!!conditionalRequired}/>,
            isRequired: !!conditionalRequired,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        }
    ]
}

PersonalDetailFieldObj.prototype.fieldObj11 = function() {
    return [
        {
            name: "otherCitizenship",
            label: <Label label={"Other citizenship (if dual citizenship)"} />,
            isRequired: false,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        }
    ]
}
export default new PersonalDetailFieldObj();