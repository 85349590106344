import React, { useCallback, useEffect } from "react";
import { Button, Label } from "../../../../../components/formInputs";
import {
    ErrorCommon,
    NumberTextField,
    NumberTextFieldWithCb,
    TextField,
    TextFieldCommon,
} from "../../../../../components/formikFormInputs";
import { Grid, Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import numeral from "numeral";
import moment from "moment";
import PolicyYearDocTable from "./PolicyYearDocTable";
import { Field, FieldArray, useFormikContext } from "formik";
import { parseAndFormat } from "../../../../../utils/commonUtils";

interface BreakDownFormPanelProps {
    setPolicyArr: Function,
    policyArr: any,
    policyArrIndex: number,
    year: number,
    setRefresh: Function,
    setPolicyFormDirty: Function,
    yearPolicyBreakup: any,
    outStandingBalance: number,
    fetchCaseDetail: any,
    expanded: any,
    combineClientObj: { key: number, name: string, title: string },
    caseDetails: any
}


const BreakDownFormPanel1 = (props: BreakDownFormPanelProps) => {
    let n = 0;

    const {
        setPolicyArr,
        policyArr,
        policyArrIndex,
        year,
        setRefresh,
        setPolicyFormDirty,
        yearPolicyBreakup,
        caseDetails,
    } = props;

    const { values, handleChange, setFieldValue, errors, touched }: any = useFormikContext();

    let fieldObj = [
        {
            id: "currentAge",
            name: "currentAge",
            label: <Label label={"Client Age"} />,
            placeholder: "Current Age",
            isRequired: true,
            component: NumberTextField,
            filedType: "number",
            variant: "outlined",
            className: "fullWidthcustomFields",
            disabled: true,
        },
        {
            id: "paymentFrequency",
            name: "paymentFrequency",
            label: <Label label={"Contribution Frequency"} />,
            placeholder: "Contribution Frequency",
            component: TextField,
            disablePast: false,
            className: "fullWidthcustomFields",
            disabled: true,
        },
        {
            id: "deathBenefitReduction",
            name: "deathBenefitReduction",
            label: <Label label={"Death Benefit Reductions"} />,
            placeholder: "Death Benefit Reductions",
            isRequired: true,
            component: NumberTextField,
            filedType: "number",
            variant: "outlined",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },


    ];

    const indexAllocation = [
        {
            id: "indexAllocation",
            name: "indexAllocation",
            label: <Label label={"Index Allocation"} />,
            placeholder: "Index Allocation",
            isRequired: true,
            component: TextField,
            filedType: "string",
            variant: "outlined",
            className: "fullWidthcustomFields",
        },
        {
            id: "indexAllocationPercent",
            name: "indexAllocationPercent",
            label: <Label label={"Index Allocation Percent"} />,
            placeholder: "Index Allocation Percent",
            isRequired: true,
            component: NumberTextFieldWithCb,
            filedType: "number",
            variant: "outlined",
            className: "fullWidthcustomFields",
            allowMax100: true,
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">%</InputAdornment>,
            },
            cb: (e: any) => {
                let val = numeral(e.target.value).value();
                setFieldValue(e.target.id, val);
            },
            allowNegative: false,
        },
    ];

    const illustratedValue = [
        {
            id: "illustratedPremium",
            name: "illustratedPremium",
            label: <Label label={"Illustrated Premium   "} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "bankPremium",
            name: "bankPremium",
            label: <Label label={"Bank Premium"} />,
            placeholder: "Bank Premium",
            isRequired: true,
            component: NumberTextField,
            filedType: "number",
            variant: "outlined",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "loanRate",
            name: "loanRate",
            label: <Label label={"loan Rate"} />,
            isRequired: true,
            component: NumberTextField,
            filedType: "number",
            variant: "outlined",
            className: "fullWidthcustomFields",
            allowMax100: true,
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">%</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "projectedInterest",
            name: "projectedInterest",
            label: <Label label={"Projected Interest"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            helperText: "Through loan anniversary.",
            allowNegative: false,
        },
        {
            id: "estLoanBalance",
            name: "estLoanBalance",
            label: <Label label={"Est. Loan Balance"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "illustratedSurrenderValue",
            name: "illustratedSurrenderValue",
            label: <Label label={"Illustrated Surrender Value"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "netCashValueIllustrated",
            name: "netCashValueIllustrated",
            label: <Label label={"Estimated Cash Value"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "netLoanCashValueIllustrated",
            name: "netLoanCashValueIllustrated",
            label: <Label label={"Net of Loan Cash Value Illustrated"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
        },
        {
            id: "illustratedCurrentDeathBenefit",
            name: "illustratedCurrentDeathBenefit",
            label: <Label label={"Illustrated Current Death Benefit"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "illustratedNetDeathBenefit",
            name: "illustratedNetDeathBenefit",
            label: <Label label={"Illustrated Net Death Benefit"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "lowPoint",
            name: "lowPoint",
            label: <Label label={"Low Point"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "illustratedGrowthRate",
            name: "illustratedGrowthRate",
            label: <Label label={"Illustrated Growth Rate"} />,
            isRequired: true,
            component: NumberTextField,
            filedType: "number",
            variant: "outlined",
            className: "fullWidthcustomFields",
            allowMax100: true,
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">%</InputAdornment>,
            },
            allowNegative: false,
        },
    ];

    const actualValues = [
        {
            id: "insurancePremiumActual",
            name: "insurancePremiumActual",
            label: <Label label={"Insurance Premium Actual"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "amountPaidToPolicy",
            name: "amountPaidToPolicy",
            label: <Label label={"Cumulative Amount Paid To Policy"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
            disabled: true,
        },
        {
            id: "bankLoanPremiumDue",
            name: "bankLoanPremiumDue",
            label: <Label label={"Bank Loan Premium Paid"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
            cb: (e: any) => {
                const val = numeral(e.target.value).value();
                const arrangementFee = numeral(values.arrangementFee).value();
                const bankInterestRate = numeral(values.bankInterestRate).value();

                //PolicyBreakDowns.actualInterestAmount[0] = (bankLoanPremiumDue[0] + arrangementFee) * (bankInterestRate[0] * 365/360)
                const actualInterestAmount = (val + arrangementFee) * (bankInterestRate * 365 / 360);

                console.log("Actual Interest Amount", actualInterestAmount);
                setFieldValue("actualInterestAmount", parseAndFormat(actualInterestAmount));

                //PolicyBreakDowns.bankLoanAmount[0] = bankLoanPremiumDue[0] + arrangementFee + actualInterestAmount[0]
                const bankLoanAmount = val + arrangementFee + actualInterestAmount;
                console.log("Bank Loan Amount", parseAndFormat(bankLoanAmount));

                setFieldValue("bankLoanAmount", parseAndFormat(bankLoanAmount));
                setFieldValue("bankLoanPremiumDue", val);
            },
        },
        {
            id: "cumulativeContributionPaid",
            name: "cumulativeContributionPaid",
            label: <Label label={"Cumulative Client Contribution Paid"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
            disabled: true,
        },
        {
            id: "cumulativeEmployerContributionPaid",
            name: "cumulativeEmployerContributionPaid",
            label: <Label label={"Cumulative Employer Contribution Paid"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
            disabled: true,
        },
        {
            id: "cumulativeBankLoanPremiumPaid",
            name: "cumulativeBankLoanPremiumPaid",
            label: <Label label={"Cumulative Bank Loan Premium Paid"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
            disabled: true,
        },
        {
            id: "bankInterestRate",
            name: "bankInterestRate",
            label: <Label label={"Bank Interest Rate"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            allowMax100: true,
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">%</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "actualInterestAmount",
            name: "actualInterestAmount",
            label: <Label label={"Actual Interest Amount"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            disabled: true,
            allowNegative: false,
        },
        {
            id: "bankLoanAmount",
            name: "bankLoanAmount",
            label: <Label label={"Estimated Loan Balance"} />,
            isRequired: true,
            component: NumberTextFieldWithCb,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            disabled: true,
            allowNegative: false,
        },
        {
            id: "actualSurrenderValue",
            name: "actualSurrenderValue",
            label: <Label label={"Actual Surrender Value"} />,
            isRequired: true,
            component: NumberTextFieldWithCb,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            cb: (e: any) => {
                const val = numeral(e.target.value).value();
                // const bankLoanAmountVal = numeral(values.bankLoanAmount).value();
                //PolicyBreakDowns.actualNetSurrenderValue[0] = {PolicyBreakDowns.actualSurrenderValue[0]} - {PolicyBreakDowns.bankLoanAmount[0]}
                // const result = val - bankLoanAmountVal;
                //setFieldValue("actualNetSurrenderValue", result + "");
                setFieldValue("actualSurrenderValue", val + "");
            },
            allowNegative: false,
        },
        {
            id: "actualNetSurrenderValue",
            name: "actualNetSurrenderValue",
            label: <Label label={"Net of Loan Cash Value Actual"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            disabled: true,
            allowNegative: false,
        },
        {
            id: "actualCurrentDeathBenefit",
            name: "actualCurrentDeathBenefit",
            label: <Label label={"Actual Current Death Benefits"} />,
            isRequired: true,
            component: NumberTextFieldWithCb,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            cb: (e: any) => {
                let val = numeral(e.target.value).value();
                let bankLoanAmountVal = numeral(values.bankLoanAmount).value();
                let result = val - bankLoanAmountVal;
                //PolicyBreakDowns.netDeathBenefitActual[0] =  actualCurrentDeathBenefit[0] - bankLoanAmount[0]
                setFieldValue("netDeathBenefitActual", parseAndFormat(result));
                setFieldValue("actualCurrentDeathBenefit", val);
            },
            allowNegative: false,
        },
        {
            id: "netDeathBenefitActual",
            name: "netDeathBenefitActual",
            label: <Label label={"Net Death Benefit Actual"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
            disabled: true,
        },
        {
            id: "growthPercent",
            name: "growthPercent",
            label: <Label label={"Policy Growth Rate"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            allowMax100: true,
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">%</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "growthAmount",
            name: "growthAmount",
            label: <Label label={"Growth Dollar Amount Earned"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "growthRateAverage",
            name: "growthRateAverage",
            label: <Label label={"Growth Rate Average"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            allowMax100: true,
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">%</InputAdornment>,
            },
            allowNegative: false,
            disabled: true,
        },
        {
            id: "loanRateAverage",
            name: "loanRateAverage",
            label: <Label label={"Loan Rate Average"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            allowMax100: true,
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">%</InputAdornment>,
            },
            allowNegative: false,
            disabled: true,
        },
        {
            id: "actualLowPoint",
            name: "actualLowPoint",
            label: <Label label={"Actual Low Point"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
    ];
    useEffect(() => {
        if (fieldObj && fieldObj.length > 0) {
            let obj: any = {};
            for (let x = 0; x < fieldObj.length; x++) {
                obj[fieldObj[x].name] = numeral(values[fieldObj[x].name]).value();
            }
            for (let x = 0; x < illustratedValue.length; x++) {
                obj[illustratedValue[x].name] = numeral(values[illustratedValue[x].name]).value();
            }
            for (let x = 0; x < actualValues.length; x++) {
                obj[actualValues[x].name] = numeral(values[actualValues[x].name]).value();
            }
            obj["indexAllocations"] = values["indexAllocations"];
            obj["policyYear"] = year;
            obj["id"] = yearPolicyBreakup.id || null;
            policyArr[policyArrIndex] = obj;
            setPolicyArr(policyArr);
            setRefresh();
        }
    }, [values]);

    const handleBlur = useCallback((e, name) => {
        let polVal = policyArr[policyArrIndex];
        if (name.substr(0, 16) === "indexAllocations") {
            polVal[`${name}`] = e.target.value;
        } else {
            polVal[`${name}`] = numeral(e.target.value).value();
        }
        policyArr[policyArrIndex] = polVal;
        setPolicyArr(policyArr);
        setRefresh();
        setPolicyFormDirty();
        n++;
    }, []);

    const handleDateBlur = (val, name) => {
        let date = moment(val).format("YYYY-MM-DD");
        let polVal = policyArr[policyArrIndex];
        polVal[`${name}`] = date;
        policyArr[policyArrIndex] = polVal;
        setPolicyArr(policyArr);
        setRefresh();
        setPolicyFormDirty();
    };
    return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <span className="hLine" />
                    </Grid>
                    {fieldObj.map((field, index) => {
                        return (
                                <Grid item xs={12} md={6} xl={6}
                                      key={index} className="mb15">
                                    <TextFieldCommon
                                            {...field}
                                            name={field.name}
                                            values={values}
                                            onChange={handleChange}
                                            onBlur={(e) => handleBlur(e, field.name)}
                                            cb={(da) => {
                                                setTimeout(() => {
                                                    handleDateBlur(da, field.name);
                                                }, 1000);
                                            }}
                                    />
                                    <ErrorCommon errors={errors} name={field.name}
                                                 touched={touched} />
                                </Grid>
                        );
                    })
                    }
                    <FieldArray name="indexAllocations">
                        {({ remove, push }) => (
                                <>
                                    {values.indexAllocations && values.indexAllocations.map((field, index) => (
                                            <>
                                                <Field type="hidden" name={`indexAllocations.${index}.id`} />
                                                {indexAllocation.map((fieldProps, subIndex) => {
                                                    return (
                                                            <Grid item xs={12} md={6}
                                                                  key={index + "-" + subIndex} className="mb15">
                                                                <Field
                                                                        {...fieldProps}
                                                                        id={`indexAllocations.${index}.${fieldProps.name}`}
                                                                        name={`indexAllocations.${index}.${fieldProps.name}`}
                                                                        onChange={fieldProps.cb ? fieldProps.cb : handleChange}
                                                                        onBlur={(e) => handleBlur(e, `indexAllocations.${index}.${fieldProps.name}`)}
                                                                        value={values.indexAllocations[index][fieldProps.name]}
                                                                        fullWidth
                                                                />
                                                                <ErrorCommon errors={errors}
                                                                             name={`indexAllocations.${index}.${fieldProps.name}`}
                                                                             touched={touched} />
                                                            </Grid>
                                                    );
                                                })}
                                                {index > 0 && <Grid item xs={2} md={2} xl={2}>
                                                    <Button
                                                            color={"primary"}
                                                            size={"small"}
                                                            label={"Remove"}
                                                            className={"btnRed"}
                                                            onClick={() => remove(index)}>
                                                    </Button>
                                                </Grid>}
                                            </>
                                    ))}
                                    <Grid item xs={12}>
                                        <Button
                                                color={"primary"}
                                                size={"small"}
                                                variant={"contained"}
                                                label={"Add Allocation"}
                                                onClick={() => push({
                                                    id: "",
                                                    indexAllocation: "",
                                                    indexAllocationPercent: "",
                                                })}>
                                        </Button>
                                    </Grid>
                                </>
                        )}
                    </FieldArray>


                    <Grid item xs={12}>
                        <span className="hLine" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1"><strong>Illustrated Values</strong></Typography>
                    </Grid>
                    {illustratedValue.map((field, index) => {
                        return (
                                <Grid item xs={6} md={6} xl={4}
                                      key={index} className="mb15">
                                    <TextFieldCommon
                                            {...field}
                                            name={field.name}
                                            values={values}
                                            onChange={handleChange}
                                            onBlur={(e) => handleBlur(e, field.name)}
                                    />
                                    <ErrorCommon errors={errors} name={field.name}
                                                 touched={touched} />
                                </Grid>
                        );
                    })
                    }
                    <Grid item xs={12}>
                        <span className="hLine" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1"><strong>Actual Values</strong></Typography>
                    </Grid>
                    {actualValues.map((field, index) => {
                        return (
                                <Grid item xs={6} md={6} xl={4}
                                      key={index} className="mb15">
                                    <TextFieldCommon
                                            {...field}
                                            name={field.name}
                                            values={values}
                                            onChange={field.cb ? field.cb : handleChange}
                                            onBlur={(e) => handleBlur(e, field.name)}
                                    />
                                    <ErrorCommon errors={errors} name={field.name}
                                                 touched={touched} />
                                </Grid>
                        );
                    })
                    }
                    <PolicyYearDocTable yearPolicyBreakup={yearPolicyBreakup} caseDetails={caseDetails} />
                </Grid>
            </React.Fragment>
    );
};
export default BreakDownFormPanel1;