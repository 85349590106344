import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography/Typography";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
} from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Label } from "../../../../../components/formInputs";
import { SelectField, TextFieldCommon } from "../../../../../components/formikFormInputs";
import { Formik } from "formik";
import AdministratorMessage from "../modals/AdministratorMessage";
import RequestModification from "../modals/RequestModification";
import DeclineEnrollment from "../modals/DeclineEnrollment";
import ResetPayment from "../modals/ResetPayment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { dateUtils } from "../../../../../utils";
import { useActions } from "../../../../../actions";
import * as Actions from "../../../../../actions/enrollment";
import * as AccountActions from "../../../../../actions/account";
import PaymentReceivedPopUp from "../../../../../components/client/PaymentReceivedPopUp";
import { UploadProposalPopUp } from "../../../../../components/client/Proposal";
import {
    AccountType,
    ActionItems as CurrentActionsItems,
    cashStreamSlug,
    ClientDocumentType,
    docusignRoles,
    GWT_GEN2_STRATEGY,
} from "../../../../../constants";
import PaymentProcessedPopUp from "../../../../../components/client/PaymentProcessedPopUp";
import DocusignLoader from "../../../../../components/DocusignLoader";
import * as Aws from "../../../../../actions/aws";
import CommonLoader from "../../../../../components/CommonLoader";
import numeral from "numeral";
import { TicketButton } from "../../../../ActionItemsList";
import * as TicketActions from "../../../../../actions/ticket";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import UploadSpecificDocument from "../dialogs/UploadSpecificDocument";
import TableWrap from "../../../../../components/TableWrap/TableWrap";
import { noop, pez, sleep, va } from "../../../../../utils/commonUtils";
import ProposalTabs from "../../../Enrollment/component/ProposalTabs";
import { Proposal } from "../../../../../model/proposal";
import { ClientDocsInterface } from "../../../../../model/documents";
import { isAllianzApp } from "../../../Enrollment/EnrollmentHome/EnrollmentApplication/Utilities";
import * as ApplicationActions from "../../../../../actions/application";
import * as ClientActions from "../../../../../actions/client";
import { Dialog as GenericDialog, GenericDialogProps } from "../../../../../components/dialog";
import QCPopUp from "../../../../../components/client/Proposal/ActionDailogs/QCPopUp/QCPopUp";
import ConfirmContributionFrequency from "../ConfirmContributionFrequency";
import CheckWirePopup from "../../../../../components/client/Proposal/ActionDailogs/CheckWirePopup/CheckWire";
import ConfirmationProposalPopUp from "../../../Enrollment/component/ConfirmationProposalPopUp";


export const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
    },
    table: {
        minWidth: 767,
    },
    tableHead: {
        backgroundColor: "#EDF3F8",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    buttonProgress: {
        color: "white",
    },
    buttonDialog: {
        display: "flex",
        justifyContent: "space-between",
    },
}));


export default function ActionItemsTab(props: { role: number | undefined, clientDetail: any, setActionItem: Function, goToDocuments: Function }) {
    const { role, clientDetail, setActionItem, goToDocuments } = props;
    const paymentFrequency: string = (clientDetail && clientDetail.client && clientDetail.client.clientPolicies && clientDetail.client.clientPolicies[0] && clientDetail.client.clientPolicies[0].paymentFrequency )|| "annual";
    const enrollmentActions = useActions(Actions);
    const { getClientDocumentByType } = useActions(AccountActions);
    const accountActions = useActions(AccountActions);
    const {getClient} = useActions(ClientActions);
    const applicationActions = useActions(ApplicationActions);
    let enrollmentId: any = clientDetail && clientDetail.enrollment ? clientDetail.enrollment.id : "";
    const clientId: number = clientDetail && clientDetail.client && clientDetail.client.id;
    const agentId: number = clientDetail && clientDetail.client && clientDetail.client.agent ? clientDetail.client.agent.id : "";
    const clientUserId = clientDetail && clientDetail.client && clientDetail.client.userId;
    const client: any = clientDetail && clientDetail.client;
    const offerType = client && client.underWritingOfferType || client && client.underWritingOfferTypeFinal;
    const navigateTo = useHistory();

    const user = useSelector((state: RootState) => state.auth.user);

    const classes = useStyles();
    const [adminMessage, setAdminMessage] = React.useState(false);
    const [requestModification, setRequestModification] = React.useState(false);
    const [declineEnrollment, setDeclineEnrollment] = React.useState(false);
    const [resetPayment, setResetPayment] = React.useState(false);
    const [documentUrl, setDocumentUrl] = React.useState("");
    const [openPdf, setOpenPdf] = React.useState(false);
    const [openProposalPdf, setOpenProposalPdf] = React.useState("");
    const [inProgress, setInProgress] = React.useState(true);
    const [actionId, setActionId] = React.useState(0);
    const [uploadProposalPopUp, setUploadProposalPopUp] = React.useState(false);
    const [paymentReceivedPopUp, setPaymentReceivedPopUp] = React.useState(false);
    const [CheckWirePopUpOpen, setCheckWirePopUpOpen] = React.useState(false);
    const [QCPopUpOpen, setQCPopUpOpen] = React.useState(false);
    const [paymentProcessedPopUp, setPaymentProcessedPopUp] = React.useState(false);
    const [currentActionItems, setCurrentActionItems] = React.useState<any>([]);
    const [completedActionItems, setCompletedActionItems] = React.useState<any>([]);
    const initialValues = {};
    const awsActions = useActions(Aws);
    const [downloadProgress, setDownloadProgress] = React.useState(false);
    const ticketActions = useActions(TicketActions);
    const [actionItemSelected, setActionItemSelected] = React.useState(null);
    const [coverLetterDialog, setCoverLetterDialog] = React.useState(false);
    const [coverLetterUpload, setCoverLetterUpload] = React.useState(true);
    const [activeProposalTab, setActiveProposalTab] = React.useState(0);
    const [enableActions, setEnableActions] = React.useState(true);
    const [proposalPdf, setProposalPdf] = React.useState<Proposal[]>([] as Proposal[]);
    const [viewApproveProposal, setViewApproveProposal] = React.useState(false);
    const isNationalLife = user.nationalLife;
    // clears proposal states
    const onClearProposal = () => {
        setOpenProposalPdf("");
        setProposalPdf([]);
    };
    const getClientData = async () => {
        await getClient(clientId, true);
        await sleep(500);
        return true;
    }

    React.useEffect(() => {
        let currentActionItems: any = clientDetail && clientDetail.actionItems && clientDetail.actionItems.length > 0 && clientDetail.actionItems.filter((actionItem) => {
            return actionItem.status === 0;
        });
        let completedActionItems: any = clientDetail && clientDetail.actionItems && clientDetail.actionItems.length > 0 && clientDetail.actionItems.filter((actionItem) => {
            return actionItem.status === 1;
        });
        setCurrentActionItems(currentActionItems);
        setCompletedActionItems(completedActionItems);
        return (() => {
            setCurrentActionItems([]);
            setCompletedActionItems([]);
        });
    }, [clientDetail, uploadProposalPopUp, openPdf]);

    //Get Envelope for Agents
    const getEnvelope = async (enrollmentId: string, offerType?: any) => {
        setOpenPdf(true);
        let envelopeUrl = "";
        if (isAllianzApp(offerType)) {
            const result = await applicationActions.getAndInitiateDocusign(enrollmentId);
            if (result) {
                envelopeUrl = result.envelopeUrl;
                setDocumentUrl(envelopeUrl);
                setInProgress(false);
            }
        } else {
            let pageUrl = window.location.protocol + "//" + window.location.hostname;
            if (window.location.hostname === "localhost") {
                pageUrl = pageUrl + ":3000";
            }
            pageUrl += "/new-client-detail/thanks";
            let result = await enrollmentActions.getEnvelope(enrollmentId, pageUrl);
            if (result && result && result.payload.data) {
                envelopeUrl = result.payload.data.envelopeUrl;
                setDocumentUrl(envelopeUrl);
                setInProgress(false);
            }
        }
    };

    //Get Envelope for Admin
    const getAdminEnvelope = async (enrollmentId: string, offerType?: any) => {
        setOpenPdf(true);
        let envelopeUrl = "";
        if (isAllianzApp(offerType)) {
            const result = await applicationActions.getAndInitiateDocusign(enrollmentId);
            if (result) {
                envelopeUrl = result.envelopeUrl;
                setDocumentUrl(envelopeUrl);
                setInProgress(false);
            }
        } else {
            let pageUrl = window.location.protocol + "//" + window.location.hostname;
            if (window.location.hostname === "localhost") {
                pageUrl = pageUrl + ":3000";
            }
            pageUrl += "/new-client-detail/thanks?isAdminApprove=1";
            let result = await enrollmentActions.getEnvelope(enrollmentId, pageUrl);
            if (result && result && result.payload.data) {
                envelopeUrl = result.payload.data.envelopeUrl;
                setDocumentUrl(envelopeUrl);
                setInProgress(false);
            }
        }
    };


    const validate = ((value: any) => {
        let errors: any = {};

        return errors;
    });

    const fieldObj = [{
        name: "createActionItem",
        label: <Label label={"Create Action Item"} required={true}/>,
        isRequired: true,
        helperFieldText: "With your current employer.",
        component: SelectField,
        className: "SelectFieldWidth floatRight",
        variant: "outlined",
        options: [{
            id: "",
            key: "",
            name: "Create Action Item",
        },
            {
                id: "1",
                key: "1",
                name: "Upload Proposal for Agent",
            }],
        onSelectChange: (val: number) => {
        },
    }];


    const handleAdministratorMessage = () => {
        setAdminMessage(true);
    };

    const handleRequestModification = () => {
        setRequestModification(!requestModification);
    };

    const handleDeclineEnrollment = () => {
        setDeclineEnrollment(true);
    };

    const handleResetPayment = () => {
        setResetPayment(true);
    };

    const handleClose = async () => {
        setAdminMessage(false);
        setRequestModification(false);
        setDeclineEnrollment(false);
        setResetPayment(false);
        await accountActions.getFreshDataToRefreshReduxState();
        setActionItem();
    };

    const handleCloseRm = async () => {
        setRequestModification(false);
        setActionItem();
    };

    const approveProposal = async (actionId?: number) => {
        await enrollmentActions.updateEnrollmentProposalActionItem({ enrollmentId, actionId });
        setActionId(0);
        onClearProposal();
        setActionItem();
        await accountActions.getFreshDataToRefreshReduxState();
    };

    const getEnvelopeForTrustee = async (enrollmentId: string, offerType?: any, reqType?: string) => {
        setOpenPdf(true);
        let envelopeUrl = "";
        if (isAllianzApp(offerType)) {
            const result = await applicationActions.getAndInitiateDocusign(enrollmentId, reqType);
            if (result) {
                envelopeUrl = result.envelopeUrl;
                setDocumentUrl(envelopeUrl);
                setInProgress(false);
            }
        } else {
            let pageUrl = window.location.protocol + "//" + window.location.hostname;
            if (window.location.hostname === "localhost") {
                pageUrl = pageUrl + ":3000";
            }
            pageUrl += "/new-client-detail/thanks?isUploadTrusteeDocs=1";
            let result = await enrollmentActions.getEnvelope(enrollmentId, pageUrl);
            if (result && result && result.payload.data) {
                envelopeUrl = result.payload.data.envelopeUrl;
                setDocumentUrl(envelopeUrl);
                setInProgress(false);
            }
        }
    };

    const getEnvelopeForSignTrusteeDoc = async (enrollmentId: string, offerType?: any, reqType?: string) => {
        setOpenPdf(true);
        let envelopeUrl = "";
        if (isAllianzApp(offerType)) {
            const result = await applicationActions.getAndInitiateDocusign(enrollmentId, reqType);
            if (result) {
                envelopeUrl = result.envelopeUrl;
                setDocumentUrl(envelopeUrl);
                setInProgress(false);
            }
        } else {
            let pageUrl = window.location.protocol + "//" + window.location.hostname;
            if (window.location.hostname === "localhost") {
                pageUrl = pageUrl + ":3000";
            }
            pageUrl += "/new-client-detail/thanks?isSignTrusteeDoc=1";
            let result = await enrollmentActions.getEnvelope(enrollmentId, pageUrl);
            if (result && result && result.payload.data) {
                envelopeUrl = result.payload.data.envelopeUrl;
                setDocumentUrl(envelopeUrl);
                setInProgress(false);
            }
        }
    };

    const downloadEnvelope = async (enrollmentId: string, recipientId?: number) => {
        setDownloadProgress(true);
        let result = await enrollmentActions.downloadEnvelope(enrollmentId, recipientId);
        if (result && result && result.payload.data) {
            let response: any = result.payload.data.fileBuf;
            let blob = new Blob([new Buffer(response, "binary")]
                    , { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const link = window.document.createElement("a");
            link.href = url;
            link.setAttribute("download", "application.pdf");
            window.document.body.appendChild(link);
            link.click();
            const payloadTrustee = {
                enrollmentId,
                actionType: "DOWNLOAD_TRUSTEE_DOCUMENTS",
            };
            await accountActions.updateTrusteeActionItemsStatus(payloadTrustee, "Document downloaded Successfully.");
            await accountActions.getFreshDataToRefreshReduxState();
            setDownloadProgress(false);
        }
    };

    const getAdminButton = (actionType: string, buttonText: string = "", role: number, action: any) => {
        let eid: string = (action && action.enrollmentId) || enrollmentId || "";

        if (actionType === CurrentActionsItems.UPLOAD_PROPOSAL.actionType && role !== 12) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatLeft mt20 ml5"
                            type={"button"}
                            onClick={() => setUploadProposalPopUp(true)}
                    >
                        {buttonText}
                    </Button>
            );
        }
        if ((actionType === CurrentActionsItems.APPROVE_DOCUSIGN.actionType) && role !== 12) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatLeft mt20 ml5"
                            type={"button"}
                            onClick={() => {
                                getAdminEnvelope(eid, offerType);
                            }}
                    >
                        {buttonText}
                    </Button>
            );
        }

        if (actionType === CurrentActionsItems.MAKE_PAYMENT_RECEIVED.actionType) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatLeft mt20 ml5"
                            type={"button"}
                            onClick={() => setPaymentReceivedPopUp(true)}
                    >
                        {buttonText}
                    </Button>
            );
        }

        if (actionType === CurrentActionsItems.QC_EPAY.actionType) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatLeft btnSmall ml5"
                            type={"button"}
                            onClick={() => {
                                setQCPopUpOpen(true)
                            }}
                    >
                        {buttonText}
                    </Button>
            );
        }

        if (actionType === CurrentActionsItems.CHECK_WIRE.actionType) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatLeft btnSmall ml5"
                            type={"button"}
                            onClick={() => {
                                setCheckWirePopUpOpen(true)
                            }}
                    >
                        {buttonText}
                    </Button>
            );
        }

        if (actionType === CurrentActionsItems.MARK_PAYMENT_PROCESSED.actionType) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatLeft mt20 ml5"
                            type={"button"}
                            onClick={() => setPaymentProcessedPopUp(true)}
                    >
                        {buttonText}
                    </Button>
            );
        }
        if ((actionType === CurrentActionsItems.UPLOAD_TRUSTEE_FINAL_DOCS.actionType) && role !== 12) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatLeft mt20 ml5"
                            type={"button"}
                            onClick={() => getEnvelopeForTrustee(eid, offerType, "illustration")}
                    >
                        {buttonText}
                    </Button>
            );
        }
        // SIGN_TRUSTEE_DOCUMENTS
        if (actionType === CurrentActionsItems.SIGN_TRUSTEE_DOCUMENTS.actionType && role === 12) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatLeft mt20 ml5"
                            type={"button"}
                            onClick={() => getEnvelopeForSignTrusteeDoc(eid, offerType)}
                    >
                        {buttonText}
                    </Button>
            );
        }
        if (actionType === CurrentActionsItems.SIGN_TRUSTEE_ILLUSTRATION.actionType && role === 12) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatLeft mt20 ml5"
                            type={"button"}
                            onClick={() => getEnvelopeForSignTrusteeDoc(eid, offerType, "illustration")}
                    >
                        {buttonText}
                    </Button>
            );
        }
        // DOWNLOAD_TRUSTEE_DOCUMENTS
        if (actionType === CurrentActionsItems.DOWNLOAD_TRUSTEE_DOCUMENTS.actionType && role === 12) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatLeft mt20 ml5"
                            type={"button"}
                            onClick={() => downloadEnvelope(eid, 6)}
                    >
                        {buttonText}
                        {downloadProgress &&
                                <CircularProgress size={24}
                                                  className={classes.buttonProgress}/>}
                    </Button>
            );
        }
        //mark_documents_approved
        if (actionType === CurrentActionsItems.MARK_DOCUMENTS_APPROVED.actionType && (role === 1 || role === 2)) {
            return (
                    <>
                        <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                className="floatLeft ml5"
                                disabled={true}
                                type={"button"}
                                onClick={() => {
                                }}
                        >
                            Mark Documents Approved
                        </Button>
                        <Link variant="body1" className="mb0 mt5 ml15 floatLeft"
                              onClick={() => goToDocuments()}>View documents</Link>
                        <br/>
                        <Typography variant="body2" align="left" className="colorRed floatLeft w100">All documents must
                            be approved before you can complete this action.</Typography>
                    </>
            );
        }
        if( actionType === CurrentActionsItems.CLEAR_ACTION_ITEM.actionType && (role === 1 || role === 2)){
            return (
                    <>
                        <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                className="floatLeft mt20 ml5"
                                type={"button"}
                                onClick={() => {
                                    accountActions.clearApprovalDocActionItem({ clientId });
                                    setActionItem();
                                }}
                        >
                            {buttonText}
                        </Button>
                    </>
            );
        }
        // cover letter required action item for admin
        if ((actionType === CurrentActionsItems.COVER_LETTER_REQUIRED.actionType ||
                actionType === CurrentActionsItems.TAX_DOCUMENT_INCOME_EARNER.actionType ||
                actionType === CurrentActionsItems.TAX_DOCUMENT_SPOUSE.actionType ||
                actionType === CurrentActionsItems.TAX_DOCUMENT_WEALTH_TRANSFER.actionType) && (role == 1 || role == 2)) {
            return <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        if (actionType !== "COVER_LETTER_REQUIRED") {
                            setCoverLetterUpload(false);
                        }
                        setCoverLetterDialog(true);
                    }}
            >
                {CurrentActionsItems[actionType].actionButton}
            </Button>;
        }
    };

    const getRole = (role: number) => {
        if (role === 1 || role === 2) {
            return "Admin";
        }
        if (role === 3) {
            return "Imo";
        }
        if (role === 4) {
            return "Agent";
        }
        if (role === 12) {
            return "Trustee";
        }
        return "Client";
    };

    const getActionName = (account: any) => {
        let actionRequired: string = account.actionRequired;
        if (account && account.enrollmentNotes && account.enrollmentNotes.author) {
            actionRequired += " - " + account.enrollmentNotes.author.firstName + " " + account.enrollmentNotes.author.lastName + " (" + getRole(account.enrollmentNotes.author.role) + ")";
        }
        if (account && account.actionType === CurrentActionsItems.MARK_DOCUMENTS_APPROVED.actionType) {
            actionRequired = CurrentActionsItems.MARK_DOCUMENTS_APPROVED.text;
        }
        if (account && account.actionType === CurrentActionsItems.CLEAR_ACTION_ITEM.actionType) {
            actionRequired = CurrentActionsItems.CLEAR_ACTION_ITEM.text;
        }
        return actionRequired;
    };

    const completedActionItemsMessage = (action: any) => {
        //let roleType: string = getRoleByRoleId(action.role);
        if (action.actionType === CurrentActionsItems.DOWNLOAD_TRUSTEE_DOCUMENTS.actionType && action.status === 1) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatLeft mt20 ml5"
                            type={"button"}
                            onClick={() => downloadEnvelope(action.enrollmentId, 6)}
                    >
                        {CurrentActionsItems.DOWNLOAD_TRUSTEE_DOCUMENTS.actionButton}
                        {downloadProgress &&
                                <CircularProgress size={24}
                                                  className={classes.buttonProgress}/>}
                    </Button>
            );
        }

        if (action.actionType === CurrentActionsItems.MAKE_PAYMENT_RECEIVED.actionType && action.status === 1) {
            return <span>{`Completed by ${docusignRoles[action.role]} amount ${client && client.receivedAmount && numeral(client.receivedAmount).format("$0,0")}`}</span>;
        } else if (action.actionType === CurrentActionsItems.MARK_PAYMENT_PROCESSED.actionType && action.status === 1) {
            return <span>{`Completed by ${docusignRoles[action.role]} amount ${client && client.processedAmount && numeral(client.processedAmount).format("$0,0")}`}</span>;
        } else {
            return <span>Completed</span>;
        }
    };

    const getCompletedByText = (user: any) => {
        return user && user.firstName ? user.firstName + " " + user.lastName + " (" + AccountType[user.role] + ")" : "";
    };

    const onClose = () => {
        setDocumentUrl("");
        setOpenPdf(false);
        setActionItem();
    };

    const enrollmentProcessProps: GenericDialogProps = {
        "aria-describedby": "enrollment-ProcessProps-dialog",
        content: (
                <>
                    <div id="alert-dialog-description" className="textCenter" style={{ height: "80vh" }}>
                        {inProgress ?
                                <Grid container justify="center" spacing={2}>
                                    <Grid item xs={12} md={12} className="textCenter">
                                        <DocusignLoader />
                                    </Grid>
                                </Grid>
                                :
                                <iframe
                                        className={"enrollmentIframe"}
                                        src={`${documentUrl}`}
                                        onLoad={noop}
                                        style={{ width: "100%", height: "80vh", border: 0 }} title={"Document"}
                                />
                        }

                        <Button id={"closebtn"} color="primary" variant="contained" className="mb20 mr30 mt15"
                                onClick={async () => {
                                    setDocumentUrl("");
                                    setOpenPdf(false);
                                    setActionItem();
                                    await accountActions.getFreshDataToRefreshReduxState();
                                }}
                                style={{ display: "none" }}
                        >
                            Close
                        </Button>

                        <Button id={"adminclosebtn"} color="primary" variant="contained" className="mb20 mr30 mt15"
                                onClick={async () => {
                                    setDocumentUrl("");
                                    setOpenPdf(false);
                                    setActionItem();
                                    setTimeout(() => accountActions.getFreshDataToRefreshReduxState(), 10000);
                                }}
                                style={{ display: "none" }}
                        >
                            Close
                        </Button>
                    </div>
                </>
        ),
        fullWidth: true,
        keepMounted: true,
        maxWidth: "lg",
        onClose: onClose,
        open: openPdf,
        title: "Enrollment Process",
    };
    const showPdfDialog = () => {
        return (
                <GenericDialog {...enrollmentProcessProps} />
        );
    };

    const showProposalPdfDialog = () => {

        return (
                <Dialog
                        fullWidth={true}
                        maxWidth={"lg"}
                        open={!!openProposalPdf}
                        aria-labelledby="Enrollment Home"
                        aria-describedby="Enrollment process"
                >
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClearProposal}>
                        <CloseIcon/>
                    </IconButton>
                    <DialogTitle id="customized-dialog-title">
                        Approve Client Proposal
                    </DialogTitle>

                    <DialogContent className="pr30 pl30">
                        <Grid container justify="center" spacing={2}>
                            {
                                    openProposalPdf === "WAITING_API_RESPONSE" &&
                                    <CommonLoader/>
                            }
                            {
                                    openProposalPdf !== "WAITING_API_RESPONSE" && va(proposalPdf) &&
                                    <ProposalTabs
                                            activeProposalTab={activeProposalTab}
                                            callBack={() => setEnableActions(true)}
                                            proposalObjArr={proposalPdf}
                                            setActiveProposalTab={setActiveProposalTab}
                                    />
                            }
                        </Grid>
                    </DialogContent>
                    {
                            enableActions &&
                            <DialogActions>
                                <Button
                                        color="primary"
                                        variant="contained"
                                        className="floatLeft"
                                        onClick={() => setViewApproveProposal(true)}
                                >
                                    Approve
                                </Button>
                                <Button
                                        color="secondary"
                                        variant="contained"
                                        className="floatRight"
                                        onClick={onClearProposal}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                    }
                </Dialog>
        );
    };

    // TODO use common getEnrollmentAdminProposalUrl
    const getEnrollmentAdminProposalUrl = async (eId: string, adminProposal: string) => {
        let file = adminProposal.split(".");
        let fileType = file[file.length - 1].toUpperCase();
        const contentType = (fileType == "PDF") ? ("text/pdf") : ("image/*");
        let queryObj = {
            objectName: adminProposal,
            contentType: contentType,
        };
        return awsActions.getSecureUrl(eId, queryObj, true);
    };

    const getCurrentActionsRows = (currentActionItems: any) => {
        if (!currentActionItems || !currentActionItems.length || currentActionItems.length < 1) {
            return (
                    <TableRow key={1 + "noCurrent"}>
                        <TableCell component="th" scope="row">
                            No current action.
                        </TableCell>
                    </TableRow>
            );
        }
        const getActionItemName = (action: any) => {
            if (client && client.strategy && client.strategy.slug === cashStreamSlug &&
                    CurrentActionsItems[action.actionType].actionType === CurrentActionsItems.SIGN_AGENT.actionType) {
                return "View and Approve";
            }
            return CurrentActionsItems[action.actionType] &&
                    CurrentActionsItems[action.actionType].actionButton;
        };

        const resolveTicket = async (ticket) => {
            await ticketActions.resolveTicket(ticket.id);
            await accountActions.getFreshDataToRefreshReduxState();
        };
        const getTextForAgent = (actionItem: any, clientDetail: any) => {
            const isGWTGen2 = clientDetail && clientDetail.client.strategy.id === GWT_GEN2_STRATEGY;
            let additionalDocName = "";
            let docReqText = "";
            if (!isGWTGen2 && actionItem && actionItem.actionType === "SIGN_AGENT" && clientDetail.client && clientDetail.client.coverLetterReq === 1) {
                docReqText = "A cover letter must be added before agent can sign application";
            }
            if (!isGWTGen2 && actionItem && actionItem.actionType === "SIGN_AGENT" && clientDetail.client && clientDetail.client.additionalTaxDocReq === 1) {
                if (clientDetail.client && clientDetail.client.currentlyEmployed.toLowerCase() === "other") {
                    if (clientDetail.client.parentsAreIncomeEarner) {  //WT
                        additionalDocName = `Wealth Transfer docs`;
                    } else {
                        additionalDocName = `Income Earner docs`;
                    }
                } else if (clientDetail && clientDetail.client && clientDetail.client.usingSpouseIncome === 1) {
                    additionalDocName = `Spouse docs`;
                }
                if (!docReqText) {
                    docReqText = `${additionalDocName} must be added before agent can sign application`;
                } else { // case if cover letter requirement is there
                    docReqText = `A cover letter and ${additionalDocName} must be added before agent can sign application`
                }
            }
            if (actionItem && actionItem.actionType === "GREAT_WEALTH_TRANSFER" && actionItem.client && actionItem.client.coverLetterReq === 1) {
                docReqText = "This is a Great Wealth Transfer Case. please complete the GWT section in case details for this case.";
            }
            return docReqText;
        }
        return (
                currentActionItems && currentActionItems.length > 0 && currentActionItems.map((action: any, index: number) =>
                        (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {getActionName(action)} <br/>
                                        {dateUtils.asDbDateFormat(action.createdAt)}
                                    </TableCell>
                                    {(user.role === 1 || user.role === 2) &&
                                            <TableCell>
                                                <TicketButton
                                                        disabled={actionItemSelected === action}
                                                        ticketId={action.currentTicket}
                                                        onCreateTicket={({
                                                                             detail,
                                                                             files,
                                                                         }) => createTicket(action, detail, files)}
                                                        onResolveTicket={resolveTicket}/>
                                            </TableCell>
                                    }
                                    <TableCell align="right">
                                        {
                                                role === 4 && !(action && action.actionType === CurrentActionsItems.GREAT_WEALTH_TRANSFER.actionType) &&
                                                <>
                                                    {
                                                        action.actionType === CurrentActionsItems.SIGN_AGENT.actionType && client && client.coverLetterReq === 1 &&
                                                            <Typography
                                                                    className="colorRed">{getTextForAgent(action, clientDetail)}
                                                            </Typography>
                                                    }
                                                    <Button
                                                            size="small"
                                                            variant="contained"
                                                            color="primary"
                                                            className="floatLeft mt20 ml5"
                                                            type={"button"}
                                                            disabled={(action.actionType === CurrentActionsItems.SIGN_AGENT.actionType && client && client.coverLetterReq === 1) || false}
                                                            onClick={async () => {
                                                                if (action.actionType === CurrentActionsItems.VIEW_APPROVE.actionType) {
                                                                    setOpenProposalPdf("WAITING_API_RESPONSE");
                                                                    let documents: any[] = await getClientDocumentByType((clientDetail && clientDetail.client && clientDetail.client.id) || 0, ClientDocumentType.ADMIN_PROPOSAL);
                                                                    const proposalArr: Proposal[] = [];
                                                                    for (let i = 0; i < documents.length; i++) {
                                                                        const proposalDocument = documents[i] as ClientDocsInterface;
                                                                        const url = await getEnrollmentAdminProposalUrl(action.enrollmentId, pez(proposalDocument.path));
                                                                        proposalArr.push({name: proposalDocument.name, url: url});
                                                                    }
                                                                    setActionId(action.id);
                                                                    setOpenProposalPdf("WAITING_API_RESPONSE_COMPLETED");
                                                                    setProposalPdf(proposalArr);
                                                                } else if (action.actionType === CurrentActionsItems.COVER_LETTER_REQUIRED.actionType) {
                                                                    setCoverLetterDialog(true);
                                                                } else if (action.actionType === CurrentActionsItems.TAX_DOCUMENT_INCOME_EARNER.actionType ||
                                                                        action.actionType === CurrentActionsItems.TAX_DOCUMENT_SPOUSE.actionType ||
                                                                        action.actionType === CurrentActionsItems.TAX_DOCUMENT_WEALTH_TRANSFER.actionType) {
                                                                    setCoverLetterUpload(false);
                                                                    setCoverLetterDialog(true);
                                                                } else {
                                                                    getEnvelope(action.enrollmentId || enrollmentId, offerType);
                                                                }
                                                            }}
                                                    >
                                                        {
                                                            getActionItemName(action)
                                                        }
                                                    </Button>
                                                    {
                                                        CurrentActionsItems[action.actionType].actionType === CurrentActionsItems.SIGN_AGENT.actionType && !isNationalLife &&
                                                        <ConfirmContributionFrequency paymentFrequency={paymentFrequency} />
                                                    }
                                                </>
                                        }
                                        {
                                                (role === 1 || role === 2 || role === 12) &&
                                                getAdminButton(action.actionType, CurrentActionsItems[action.actionType] ? CurrentActionsItems[action.actionType].actionButton : "", role, action)
                                        }
                                        {
                                                (action && action.actionType === CurrentActionsItems.GREAT_WEALTH_TRANSFER.actionType) &&
                                                <Button
                                                        className="btnSmall textBlue"
                                                        color="primary"
                                                        onClick={() => navigateTo.push(`/new-client/detail/${action.clientId || ""}/case-details`)}
                                                        size="small"
                                                        type={"button"}
                                                        variant="outlined"
                                                >
                                                    {CurrentActionsItems[action.actionType].actionButton}
                                                </Button>
                                        }
                                        {CurrentActionsItems.VIEW_APPROVE.actionType === action.actionType &&
                                                <Button
                                                        size="small"
                                                        variant="outlined"
                                                        color="primary"
                                                        className="floatLeft mt20 ml5"
                                                        type={"button"}
                                                        onClick={() => handleRequestModification()}
                                                >
                                                    Request Modification
                                                </Button>
                                        }
                                    </TableCell>
                                </TableRow>
                        ))
        );
    };

    if (!clientDetail.client) {
        return (
                <CommonLoader/>
        );
    }


    const createTicket = async (actionItem, detail, files) => {
        try {
            setActionItemSelected(actionItem);
            await ticketActions.createTicket({
                actionItemId: actionItem.id,
                detail,
                files,
            });
            await accountActions.getFreshDataToRefreshReduxState();
            setActionItem();
        } finally {
            setActionItemSelected(null);
        }
    };
    console.log(clientDetail,"===")
    return (
            <React.Fragment>
                {showPdfDialog()}
                {showProposalPdfDialog()}
                {(role === 1 || role === 2) &&
                        <Formik
                                initialValues={initialValues}
                                enableReinitialize={true}
                                validate={values => validate(values)}
                                validateOnChange={true}
                                onSubmit={async (values, { setSubmitting }) => {
                                    console.log("ClientProfileTabDataSubmitted", values);
                                }}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  setFieldValue,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                              }) => (<form onSubmit={handleSubmit}>
                                {fieldObj.map((field, index) => {
                                    return (
                                            <Box m={1} key={index}>
                                                <div className="mt30 customFields">
                                                    <Grid container spacing={2} justify="space-between">
                                                        <Grid item xs={12} sm={8} md={6}>
                                                            <Typography variant="body1">View and fulfill your current
                                                                action
                                                                items for this client. Your past completed action items
                                                                are also
                                                                listed for your records.
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4} md={4}
                                                              key={index}>
                                                            <TextFieldCommon
                                                                    {...field}
                                                                    values={1}
                                                                    //onChange={handleChange}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Box>
                                    );
                                })
                                }
                            </form>)}
                        </Formik>
                }

                <ExpansionPanel expanded={!!(currentActionItems && currentActionItems.length > 0)}>
                    <ExpansionPanelSummary
                            className="ExpansionPanelHead"
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="currentAction-content"
                            id="identity-header"
                    >
                        <Typography variant="subtitle1" className="mb0">Current Action Items</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="contentPadding">
                        <TableWrap>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell>Action Item</TableCell>
                                    {(user.role === 1 || user.role === 2) &&
                                            <TableCell>Tickets</TableCell>
                                    }
                                    <TableCell align="left">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getCurrentActionsRows(currentActionItems)}
                                <TableRow key={1}>
                                    <TableCell component="td" scope="row" colSpan={4}>
                                        <Typography variant="body2" align="center" className="mt15 mb15">Item will show
                                            in the list when action is
                                            required on your part</Typography></TableCell>
                                </TableRow>
                            </TableBody>
                        </TableWrap>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <br/>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                            className="ExpansionPanelHead"
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="currentAction-content"
                            id="identity-header"
                    >
                        <Typography variant="subtitle1" className="mb0">Completed Action Items</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="contentPadding">
                        <TableWrap>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell>Action Item</TableCell>
                                    <TableCell>Assigned On</TableCell>
                                    <TableCell>Data Completed</TableCell>
                                    <TableCell>Completed By</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {completedActionItems && completedActionItems.length === 0 ?
                                        <TableRow key={1 + "noAction"}>
                                            <TableCell component="th" scope="row">
                                                No completed action.
                                            </TableCell>
                                        </TableRow>
                                        :
                                        (completedActionItems && completedActionItems.length > 0 && completedActionItems.map((action, index) => {
                                            return (

                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {getActionName(action)}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {dateUtils.asDbDateFormat(action.createdAt)}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {dateUtils.asDbDateFormat(action.updatedAt)}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {getCompletedByText(action.user)}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {completedActionItemsMessage(action)}
                                                        </TableCell>
                                                    </TableRow>
                                            );
                                        }))
                                }
                                <TableRow key={1}>
                                    <TableCell component="td" scope="row" colSpan={4}>
                                        <Typography variant="body2" align="center" className="mt15 mb15">Complete
                                            items
                                            will move to this list</Typography></TableCell>
                                </TableRow>
                            </TableBody>
                        </TableWrap>
                    </ExpansionPanelDetails>
                </ExpansionPanel>


                <AdministratorMessage handleAdministratorMessage={handleAdministratorMessage} handleClose={handleClose}
                                      open={adminMessage}/>
                <RequestModification handleAdministratorMessage={handleRequestModification} handleClose={handleCloseRm}
                                     open={requestModification} enrollmentId={enrollmentId} />
                <DeclineEnrollment handleDeclineEnrollment={handleDeclineEnrollment} handleClose={handleClose}
                                   open={declineEnrollment}/>
                <ResetPayment handleDeclineEnrollment={handleResetPayment} handleClose={handleClose}
                              open={resetPayment}/>
                {
                        (uploadProposalPopUp) &&
                        <UploadProposalPopUp
                                client={client}
                                clientId={clientId}
                                enrollmentId={enrollmentId}
                                open={uploadProposalPopUp}
                                refreshList={() => getClientData()}
                                setUploadProposalPopUp={setUploadProposalPopUp}
                        />
                }
                {
                        paymentReceivedPopUp &&
                        <PaymentReceivedPopUp setPaymentReceivedPopUp={setPaymentReceivedPopUp}
                                              open={paymentReceivedPopUp}
                                              enrollmentId={enrollmentId} clientId={clientId}
                                              agentId={agentId}
                                              clientUserId={clientUserId}
                                              client={client}
                                              refreshList={async () => {
                                                  setActionItem();
                                                  await accountActions.getFreshDataToRefreshReduxState();
                                              }}
                        />
                }
                {
                        (QCPopUpOpen) &&
                        <QCPopUp
                                refreshList={async () => {
                                    setActionItem();
                                    await accountActions.getFreshDataToRefreshReduxState();
                                }}
                                client={client}
                                setOpenPopup={setQCPopUpOpen}
                                openPopup={QCPopUpOpen}
                                enrollmentId={enrollmentId}
                        />
                },
                {
                        (CheckWirePopUpOpen) &&
                        <CheckWirePopup
                                refreshList={async () => {
                                    setActionItem();
                                    await accountActions.getFreshDataToRefreshReduxState();
                                }}
                                client={client}
                                setOpenPopup={setCheckWirePopUpOpen}
                                openPopup={CheckWirePopUpOpen}
                                enrollmentId={enrollmentId}
                        />
                }
                {
                        paymentProcessedPopUp &&
                        <PaymentProcessedPopUp
                                setPaymentProcessedPopUp={setPaymentProcessedPopUp}
                                open={paymentProcessedPopUp}
                                enrollmentId={enrollmentId}
                                clientId={clientId}
                                agentId={agentId}
                                clientUserId={clientUserId}
                                client={client}
                                refreshList={async () => {
                                    setActionItem();
                                    await accountActions.getFreshDataToRefreshReduxState();
                                }}
                        />
                }
                {coverLetterDialog &&
                        <UploadSpecificDocument
                                clientDetail={clientDetail}
                                open={coverLetterDialog}
                                handleClose={async () => {
                                    setCoverLetterDialog(false);
                                    setActionItem();
                                    await accountActions.getFreshDataToRefreshReduxState();
                                }}
                                uploadCoverLetter={coverLetterUpload}
                                uploadAdditionalTaxDocs={!coverLetterUpload}
                                setCoverLetterReq={() => {
                                }}
                                coverLetterUploadedFromActionItem={coverLetterUpload}
                        />
                }
                <ConfirmationProposalPopUp
                        setPopupStatus={setViewApproveProposal}
                        setModification={handleRequestModification}
                        setModificationVal={null}
                        setApprove={approveProposal}
                        setApproveVal={actionId}
                        popupStatus={viewApproveProposal}
                        text1={`Your Client has chosen to pay a ${paymentFrequency} contribution. if this is correct, please approve this proposal.`}
                        text2={`if your client would like to change their ${paymentFrequency} contribution amount or frequency, please request a modified proposal with the correct amount and/or frequency.`}
                        changeModification={`Request Modification`}
                        proceed={`Approve`}
                />
            </React.Fragment>
    );
}
